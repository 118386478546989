import { ClickAwayListener, useSnackbar } from '@mui/base'
import React, { Dispatch, PropsWithChildren, SetStateAction } from 'react'
import { createPortal } from 'react-dom'
import { createContext } from 'react'

export enum INotificationStatus {
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
}

interface INotificationContext {
  message: string
  status: INotificationStatus
  open: boolean
  setMessage: (message: string, status?: INotificationStatus) => void
  setOpen: Dispatch<SetStateAction<boolean>>
}

const NotificationContext = createContext<INotificationContext | undefined>(
  undefined
)

export const NotificationContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [message, setMessage] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [status, setStatus] = React.useState<INotificationStatus>(
    INotificationStatus.ERROR
  )

  const setMessageAndOpen = (
    message: string,
    status = INotificationStatus.ERROR
  ) => {
    setMessage(message)
    setOpen(true)
    setStatus(status)
  }

  const contextValue = {
    open,
    message,
    status,
    setOpen,
    setMessage: setMessageAndOpen,
  }

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  )
}

export function useNotificationContext() {
  const context = React.useContext(NotificationContext)
  if (!context) {
    throw new Error(
      'useNotificationContext must be used within a NotificationContextProvider'
    )
  }
  return context
}

export function Notification() {
  const { open, setOpen, message, status } = useNotificationContext()

  const handleClose = () => {
    setOpen(false)
  }

  const { getRootProps, onClickAway } = useSnackbar({
    onClose: handleClose,
    open,
    autoHideDuration: 5000,
  })

  return createPortal(
    <React.Fragment>
      {open ? (
        <ClickAwayListener onClickAway={onClickAway}>
          <div
            {...getRootProps()}
            className={`notification fixed z-50 flex right-8 top-16 px-4 py-2 rounded-md text-white transition-transform animate-notification ${
              status === INotificationStatus.ERROR
                ? 'bg-red-500'
                : status === INotificationStatus.SUCCESS
                  ? 'bg-green-500'
                  : status === INotificationStatus.INFO
                    ? 'bg-blue-500'
                    : 'bg-yellow-500'
            }`}
          >
            {message}
          </div>
        </ClickAwayListener>
      ) : null}
    </React.Fragment>,
    document.getElementById('headlessui-portal-root') ??
      document.getElementById('notification-root')
  )
}
