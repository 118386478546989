import { Inter } from 'next/font/google'
import localFont from 'next/font/local'

const inter = Inter({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700'],
  variable: '--font-inter',
})
const planarGt = localFont({
  src: [
    {
      path: '../../../public/fonts/GT-Planar-Regular.woff',
      style: 'normal',
    },
  ],
  variable: '--font-gt-planar',
})

const useCustomFonts = () => {
  return {
    inter,
    planarGt,
  }
}

export default useCustomFonts
