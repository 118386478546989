import { PRIVATE_ROUTES, ROUTES } from './constants'

export function logout(redirectTo = '/') {
  if (
    window.location.pathname.includes(ROUTES.logout) ||
    window.location.pathname.includes(ROUTES.login)
  ) {
    return
  }

  window.location.href = `${ROUTES.logout}?redirectTo=${redirectTo}`
}

export function isPrivateRoute(route: string) {
  return PRIVATE_ROUTES.some((privateRoute: string | RegExp) => {
    if (privateRoute instanceof RegExp) {
      return privateRoute.exec(route)
    }

    return privateRoute === route
  })
}
