import { stringToSlug } from '../lib/string'
import { ApiService } from './ApiService'
import { IUserProfile } from '@/types/profile'

export type IUserProfilePostPayload = {
  cvFile: File
  linkedInUrl: string
  userId?: string
  iDontHaveLinkedInOrCV?: boolean
}

export type IUserProfilePatchPayload = Partial<IUserProfile>

export class UserProfileService extends ApiService {
  static GET_PATH = '/profiles'
  static GET_INDIVIDUAL_PATH = '/profiles/:id'
  static POST_PATH = '/profiles'
  static PATCH_PATH = '/profiles/:id'

  static getPatchPath(profileId: string) {
    return UserProfileService.PATCH_PATH.replace(':id', profileId)
  }

  static getPostPath() {
    return UserProfileService.POST_PATH
  }

  static async getProfile(includeDocuments?: boolean) {
    const { data: profiles, ...rest } = await ApiService.get(
      includeDocuments
        ? UserProfileService.GET_PATH + `?include=documents`
        : UserProfileService.GET_PATH
    )
    return { data: profiles?.[profiles.length - 1], ...rest }
  }

  static async postUserProfile(payload: IUserProfilePostPayload) {
    if (!payload.cvFile) {
      const filteredPayload = {
        ...(payload.linkedInUrl && {
          linkedInUrl: payload.linkedInUrl,
        }),
        ...(payload.userId && {
          userId: payload.userId,
        }),
        ...(payload.companyName && {
          companyName: payload.companyName,
        }),
        ...(payload.currentRole && {
          currentRole: payload.currentRole,
        }),
        ...(payload.location && {
          location: payload.location,
        }),
        ...((payload.yearsOfExperience !== undefined ||
          payload.yearsOfExperience !== null) && {
          yearsOfExperience: payload.yearsOfExperience,
        }),
        ...(Array.isArray(payload.phoneNumbers) && {
          phoneNumbers: payload.phoneNumbers,
        }),
        ...(payload.linkedInUrl && {
          linkedInUrl: payload.linkedInUrl,
        }),
        ...(payload.address && {
          address: payload.address,
        }),
        ...(payload.learningBudget !== undefined && {
          learningBudget: payload.learningBudget,
        }),
      }

      return ApiService.post(UserProfileService.getPostPath(), filteredPayload)
    }

    const formData = new FormData()

    if (payload.cvFile) {
      formData.append('file', payload.cvFile, payload.cvFile.name)
    }

    if (payload.linkedInUrl) {
      formData.append('linkedInUrl', payload.linkedInUrl)
    }
    formData.append('userId', payload.userId ?? '')

    return ApiService.post(UserProfileService.getPostPath(), formData)
  }

  static async patchUserProfile(
    profileId: string,
    payload: IUserProfilePatchPayload | IUserProfilePatchPayload
  ) {
    // TODO: add support for patching with a file
    const filteredPayload = {
      ...(payload.companyName && {
        companyName: payload.companyName,
      }),
      ...(Array.isArray(payload.skills) && {
        skills: payload.skills.map(stringToSlug),
      }),
      ...(payload.currentRole && {
        currentRole: payload.currentRole,
      }),
      ...(payload.location && {
        location: payload.location,
      }),
      ...((payload.yearsOfExperience !== undefined ||
        payload.yearsOfExperience !== null) && {
        yearsOfExperience: payload.yearsOfExperience,
      }),
      ...(payload.careerFeeling && {
        careerFeeling: payload.careerFeeling,
      }),
      ...(payload.weeklyLearningTime && {
        weeklyLearningTime: payload.weeklyLearningTime,
      }),
      ...(payload.upskillingReason && {
        upskillingReason: payload.upskillingReason,
      }),
      ...(Array.isArray(payload.phoneNumbers) && {
        phoneNumbers: payload.phoneNumbers,
      }),
      ...(payload.linkedInUrl && {
        linkedInUrl: payload.linkedInUrl,
      }),
      ...(payload.address && {
        address: payload.address,
      }),
      ...(payload.learningBudget !== undefined && {
        learningBudget: payload.learningBudget,
      }),
    }

    return ApiService.patch(
      UserProfileService.getPatchPath(profileId),
      filteredPayload
    )
  }
}
