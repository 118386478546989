import { ApiService } from '@/app/services/ApiService'
import { UserProfileService } from '@/app/services/user-profile'
import { http, HttpResponse } from 'msw'

export const profileHandlers = [
  // Describe what request to intercept...
  http.get(`${ApiService.API_URL}${UserProfileService.GET_PATH}`, () => {
    try {
      const userData = JSON.parse(sessionStorage.getItem('mocked__user'))

      if (!userData) {
        throw new Error('No userdata')
      }

      return new HttpResponse(
        JSON.stringify({
          message: 'OK',
          statusCode: 200,
          data: userData,
        }),
        {
          status: 200,
          statusText: 'OK',
        }
      )
    } catch {
      return new HttpResponse(
        JSON.stringify({
          message: 'Not found',
          statusCode: 404,
        }),
        {
          status: 404,
          statusText: 'not found',
        }
      )
    }
  }),
  http.post(
    `${ApiService.API_URL}${UserProfileService.POST_PATH}`,
    async ({ request: req }) => {
      const USER_ID = 'aaf2c56d-1503-4e32-b868-b5ed328d006a'

      if (req.headers.get('content-type') !== 'application/json') {
        const formData = await req.formData()

        const persistedUser = {
          _id: USER_ID,
          linkedInUrl: formData.get('linkedInUrl'),
        }

        sessionStorage.setItem('mocked__user', JSON.stringify(persistedUser))

        return new HttpResponse(
          JSON.stringify({
            message: 'OK',
            statusCode: 201,
            data: persistedUser,
          })
        )
      } else {
        const requestBody = await req.json()

        const persistedUser = {
          _id: USER_ID,
          linkedInUrl: requestBody.linkedInUrl,
        }

        sessionStorage.setItem('mocked__user', JSON.stringify(persistedUser))

        return new HttpResponse(
          JSON.stringify({
            message: 'OK',
            statusCode: 201,
            data: persistedUser,
          })
        )
      }
    }
  ),
  http.patch(
    `${ApiService.API_URL}${UserProfileService.PATCH_PATH}`,
    async ({ request: req }) => {
      const requestBody = await req.json()
      const storedUser = JSON.parse(sessionStorage.getItem('mocked__user'))

      const persistedUser = {
        ...storedUser,
        ...requestBody,
      }

      sessionStorage.setItem('mocked__user', JSON.stringify(persistedUser))

      return new HttpResponse(
        JSON.stringify({
          message: 'OK',
          statusCode: 201,
          data: persistedUser,
        })
      )
    }
  ),
]
