export const stringToSlug = (input: string): string => {
  let slug = input.toLowerCase()
  slug = slug.replace(/\s+/g, '-')
  slug = slug.replace(/[^a-z0-9-]/g, '')
  return slug
}

export const slugToString = (slug: string): string => {
  let str = slug.replace(/-/g, ' ')
  str = str.replace(/\b\w/g, (char) => char.toUpperCase())
  return str
}
