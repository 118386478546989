import { UserService } from '@/app/services/user'
import { ErrorResponse, IApiIndividualResponse } from '@/types/api'
import { IUser, UserInclude } from '@/types/user'

import { useQuery, UseQueryOptions } from 'react-query'

export function getUseMyUserOptions(
  include: UserInclude[] = [],
  options?: UseQueryOptions<IApiIndividualResponse<IUser>, ErrorResponse>
) {
  return {
    queryKey: [UserService.GET_ME_PATH, ...include],
    queryFn: ({ signal }: { signal?: AbortSignal }) =>
      UserService.getMe({ include, options: { signal } }),
    ...options,
  }
}

export type UseMyUserArgs = {
  include?: UserInclude[]
  options?: Omit<
    UseQueryOptions<IApiIndividualResponse<IUser>, ErrorResponse>,
    'queryKey' | 'queryFn'
  >
}

export function useMyUser({ include, options }: UseMyUserArgs = {}) {
  return useQuery<IApiIndividualResponse<IUser>, ErrorResponse>(
    getUseMyUserOptions(include, options)
  )
}
