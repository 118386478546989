import { isBoom } from '@hapi/boom'
import { IUser, UserInclude } from '@/types/user'
import { ApiService, RequestOptions } from './ApiService'
import qs from 'qs'
import { IApiIndividualResponse, IPaginationParams } from '@/types/api'

export type IUserPutPayload = Pick<
  IUser,
  'firstName' | 'lastName' | 'jobRole' | 'linkedIn'
>
type GetMeArgs = {
  include?: UserInclude[]
  options?: RequestOptions
}

export class UserService extends ApiService {
  static GET_PATH = '/users/:id'
  static GET_ME_PATH = '/users/me'
  static LIST_USERS_PATH = '/users'
  static PUT_USER_PATH = '/users/:id'

  static async getById(userId: string, fetchOptions?: RequestOptions) {
    return ApiService.get(
      UserService.GET_PATH.replace(':id', userId),
      fetchOptions
    )
  }

  static async listUsers(
    filters?: Partial<IUser>,
    pagination?: IPaginationParams,
    options?: RequestOptions
  ) {
    const queryString = qs.stringify(
      { ...filters, ...pagination },
      { indices: false }
    )

    return ApiService.get(
      `${UserService.LIST_USERS_PATH}?${queryString}`,
      options
    )
  }

  static async getMe({ include = [], options }: GetMeArgs = {}): Promise<
    IApiIndividualResponse<IUser>
  > {
    const queryString = include
      ? qs.stringify({ include }, { indices: false })
      : undefined

    return ApiService.get(`${UserService.GET_ME_PATH}?${queryString}`, options)
  }

  static getUserPutPath(userId: string) {
    return UserService.PUT_USER_PATH.replace(':id', userId)
  }

  static putUser(userId: string, userData: IUserPutPayload) {
    if (!userId) {
      throw new Error('userId is required')
    }

    return ApiService.put(UserService.getUserPutPath(userId), userData)
  }

  static getRecommendations = async () => {
    try {
      return await ApiService.get('/recommendations')
    } catch (e) {
      // catch and return empy list to avoid triggering a logout when user is
      // not authenticated.
      if (isBoom(e, 401)) {
        return []
      } else {
        throw e
      }
    }
  }
}
